import React, { useEffect, useState, useRef, useCallback } from "react";
import ProductBreadcrumb from "components/ProductBreadcrumb/ProductBreadcrumb";
import { Card, Col, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { convertCharacter } from "services/convertCharacter";
import { ArticleCategoryInterface } from "types/articleCategory";

// Function to colour the Product category according to Mueba LF 25 Layout
//RGB and Hex values converter generated
const getCategoryColor = (id: number) => {
  switch (id) {
    case 0:
      return "black";

    //Baugeräte
    // Group 1: Baustützen und Zubehör
    case 1:
      return "rgba(46, 27, 21, 0.5)";
    //Schalungen : Schalungen soll mit Group 1 kombiniert werden zu  #Schalung und Zubehör H90 grau 40/65/72/70 in 50% = RGB = 46 27 21 HEX = #2E1B15
    case 2:
      return "green";
    // Geräte = diversere Geräte ?? brombeer 60/80/20/40 in 80%  RGB = 61,31,122 Hex = #3D1F7A
    case 3:
      return "rgba(61, 31, 122, 0.8)";
    //Absturzsicherung ocker 10/80/100/10 in 100% RGB = 207,46,0 Hex =  #CF2E00
    case 4:
      return "rgba(207,46,0,1.0)";
    //Lagerung = Lagerung/ Paletten ? H90 grau 40/65/72/70 in 80% RGB = (46,27,21) Hex = #2E1B15
    case 5:
      return "rgba(46,27,21,0.8)";
    //Gerüstböcke  ocker 10/80/100/10 in 60%   RGB = (207,46,0) Hex = #CF2E00
    case 6:
      return "rgba(207,46,0,0.6)";
    // Karren = Schubkarren/Sackkarren und Zubehör ?  brombeer 60/80/20/40 in 100% RGB = (61,31,122) Hec = #3D1F7A
    case 7:
      return "rgba(61,31,122,1.0)";
    //Absperrungen orange CMYK 0/65/100/0 in 100% RGB = (255,89,0) Hex = #FF5900
    case 8:
      return "rgba(255,89,0,1.0)";
    // Mobilzaun = Bauzäune und Zubehör H90 grau 40/65/72/70 in 80% RGB = (46,27,21) Hex = #2E1B15
    case 9:
      return "rgba(46,27,21,0.8)";


    //Baugerüste
    //Dachgerüste H91 grau 30/10/10/30 in 100% RGB = (125,161,161) Hex = #7DA1A1
    case 10:
      return "rgba(125,161,161,1.0)";
    //Fahrgerüste H91 grau 30/10/10/30 in 80% RGB = (125,161,161) Hex = #7DA1A1
    case 11:
      return "rgba(125,161,161,0.8)";
    //Gerüstsysteme = Gerüstsysteme und Zubehör brombeer 60/80/20/40 in 30% RGB = (61,31,122) Hex = #3D1F7A
    case 12:
      return "rgba(61,31,122,0.3)";
    default:
      return "black"; // Standardfarbe
  }
};
export interface ArticleCategoryListItemProps {
  category: ArticleCategoryInterface;
}

export function ArticleCategoryListItem({
  category,
}: ArticleCategoryListItemProps) {
  const [activeIndex, setActiveIndex] = useState(-1);
  const divRefs = useRef<(HTMLAnchorElement | null)[]>([]);

  const handleArrowNavigation = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "ArrowUp") {
        e.preventDefault();
        setActiveIndex((prevIndex) => Math.max(prevIndex - 1, -1));
      } else if (e.key === "ArrowLeft") {
        e.preventDefault();
        setActiveIndex((prevIndex) => Math.max(prevIndex - 1, -1));
      } else if (e.key === "ArrowDown") {
        e.preventDefault();
        setActiveIndex((prevIndex) =>
          Math.min(prevIndex + 1, (category.groups?.length || 0) - 1)
        );
      } else if (e.key === "ArrowRight") {
        e.preventDefault();
        setActiveIndex((prevIndex) =>
          Math.min(prevIndex + 1, (category.groups?.length || 0) - 1)
        );
      }
    },
    [category.groups]
  );

  useEffect(() => {
    if (divRefs.current.length > 0 && activeIndex !== -1) {
      divRefs.current[activeIndex]?.focus();
    }
  }, [activeIndex]);

  useEffect(() => {
    window.addEventListener("keydown", handleArrowNavigation);
    return () => {
      window.removeEventListener("keydown", handleArrowNavigation);
    };
  }, [handleArrowNavigation]);

  return (
    <>




      <ProductBreadcrumb />
      <Card style={{ border: "none" }}>
        <Card.Header
          style={{
            border: "none",
            backgroundColor: "#fff",
          }}

        >
          <h1 className="d-none d-xl-block">{category.names[0].name}</h1>
          <h2 className="d-xl-none">{category.names[0].name}</h2>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col md="8">
              <p
                style={{
                  verticalAlign: "top",
                }}
              >
                {convertCharacter(category.names[0].description)}
              </p>

              <Row>
                {category.groups?.filter((ci) => ci.id !== 2).map((ci, index) => ( //random Sort .sort(() => Math.random() - 0.5) .filter((group) => group.children?.length > 0) , filter ci.id = 2 better check if children length <=
                  <Col md={4} xs={8} data-aos="fade-up" key={ci.id}>
                    <Link
                      to={`/group/${ci?.id}`}
                      tabIndex={index === activeIndex ? 0 : -1}
                      ref={(el: HTMLAnchorElement) =>
                        (divRefs.current[index] = el)
                      }
                    >

                      <Card
                        style={{
                          height: "100%",
                          border: "none",
                          //backgroundColor: getCategoryColor(ci.id),
                          aspectRatio: 1 / 0.4,
                        }}
                        className={`text-black transform-hover ${index === activeIndex ? "selected-item" : ""
                          } `}
                      >
                        <Card.Header
                          style={{
                            backgroundColor: "#fff",
                            padding: 0,
                          }}
                          className="group-effect"
                        >
                          <hr style={{

                            //height: "10px",

                          }} />

                          <h3 style={{ justifyContent: "center", textAlign: "center", color: getCategoryColor(ci.id) }}>#{ci.names[0].name}</h3> {/* //TODO hier Farbklasse in h3 style={{ color: getCategoryColor(ci.id) }} einfügen */}
                          <br></br>
                        </Card.Header>
                        {/* //TODO IMG auskommentieren */}
                        {/*   <Card.Img
                          src={ci.image?.src}
                          variant="top"
                          style={{
                            aspectRatio: 4 / 5,
                            backgroundColor: getCategoryColor(ci.id),
                          }}
                         
                        /> */}



                      </Card>
                    </Link>
                  </Col>
                ))}
              </Row>
            </Col>

            <Col md="4">
              <Image
                src={category?.image.src}
                style={{
                  aspectRatio: "1 / 1",
                  objectFit: "contain",
                }}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Row></Row>
    </>
  );
}
