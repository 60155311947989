import { axios, APIResponse } from "lib/axios";
import {
  ArticleCategoryInterface,
  ArticleCategoryDetails,
} from "types/articleCategory";
import { ArticleGroup } from "types/articleGroup/index";
import { ArticleProduct } from "../../types/articleProduct";

export async function fetchArticleGroups(): Promise<ArticleGroup[]> {
  return (await axios.get<APIResponse<ArticleGroup[]>>("/articles/categories/"))
    .data.data;
}

export async function fetchArticleGroupById(id: number): Promise<ArticleGroup> {
  return (await axios.get<APIResponse<ArticleGroup>>("/articles/groups/" + id))
    .data.data;
}

export async function fetchArticleCategoryById(
  id: number
): Promise<ArticleCategoryDetails> {
  return (
    await axios.get<APIResponse<ArticleCategoryDetails>>(
      "/articles/categories/" + id
    )
  ).data.data;
}

export async function fetchArticlesByGroupId(
  id: number
): Promise<ArticleProduct[]> {
  return (
    await axios.get<APIResponse<ArticleProduct[]>>(
      "/articles/groups/" + id + "/articles"
    )
  ).data.data;
}
