import { ArticleProduct, ArticleSearch } from "../../types/articleProduct";
import { APIResponse, axios } from "../../lib/axios";

export async function fetchArticleProducts(
  id: string
): Promise<ArticleProduct> {
  return (await axios.get<APIResponse<ArticleProduct>>("/articles/" + id)).data
    .data;
}

export async function fetchProductDownload(id: number): Promise<Blob> {
  return (
    await axios.get<Blob>("/articles/" + id + "/pdf", {
      responseType: "blob",
    })
  ).data;
}

export async function fetchArticleSearch(
  searchString: string
): Promise<ArticleSearch[]> {
  return (
    await axios.get<APIResponse<ArticleSearch[]>>(
      "/article/search/" + searchString
    )
  ).data.data;
}
