import { Attachment } from "types/downloads";

export interface AttachmentListItemProps {
  attachments: Attachment[];
}

export function AttachmentListItem({ attachments }: AttachmentListItemProps) {
  return (
    <>
      <ul>
        {attachments.map((a) => (
          <li
            className="attachment"
            onClick={() => window.open(a.filepath)}
            key={a.id}
          >
            {a.names[0].title}
          </li>
        ))}
      </ul>
    </>
  );
}
