import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faSquarePhone } from "@fortawesome/free-solid-svg-icons";
import Container from "react-bootstrap/Container";

export function AppTopbar() {
  return (
    <Container fluid>
      <Row className="top-bar sticky-nav text-center">
        <Col md="12">
          <span className="d-none d-md-block top-bar" style={{ height: "7px" }}>
            <a href="tel:029358010">
              <b>
                {" "}
                <FontAwesomeIcon icon={faSquarePhone} /> +49 (2935) 801-0
              </b>
            </a>
            <a className="mx-5" href="mailto:info@mueba.de">
              <b>
                <FontAwesomeIcon icon={faEnvelope} /> service@mueba.de
              </b>
            </a>
          </span>
        </Col>
      </Row>
    </Container>
  );
}
