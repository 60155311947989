import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { fetchArticleSearch } from "services/articleProducts";
import { ArticleSearch } from "types/articleProduct";

export interface SearchBarProps {
  onClick?: () => void;
}

export function SearchBar({ onClick }: SearchBarProps) {
  const [searchInput, setSearchInput] = useState("");
  const [results, setResults] = useState<ArticleSearch[]>([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(-1);
  const ulRef = useRef<HTMLUListElement>(null);

  //fetching results from searchInput
  const fetchProducts = async (searchString: string) => {
    try {
      const product: ArticleSearch[] = await fetchArticleSearch(searchString);
      console.log(searchString);
      setResults(product);
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : (err as string);
      toast.error(errorMessage);
    }
  };

  //target fieldInput and search again
  const onUpdateField = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fieldInput = event.target.value;
    setSearchInput(fieldInput);
  };

  //clear inputField after submit
  const clearInput = useCallback(() => {
    setSearchInput("");
    setResults([]);
    if (onClick !== undefined) {
      onClick();
    }
  }, [onClick]);

  //create navigation link to product
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (results.length > 0) {
      setActiveIndex(0);
      navigate("/products/" + results[0].id);
      clearInput();
    }
  };

  //shortcut function to jump in searchField with "shift + 7"
  window.addEventListener("keydown", (e) => {
    if (e.key === "/" && e.keyCode === 55) {
      if (document.getElementById("searchBar") !== document.activeElement) {
        e.preventDefault();
        console.log("Search is not in Focus");
        document.getElementById("searchBar")?.focus();
      } else {
        return true;
      }
    }
  });

  //navigate through li with arrow keys
  const handleArrowNavigation = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "ArrowUp") {
        e.preventDefault();
        setActiveIndex((prevIndex) => Math.max(prevIndex - 1, -1));
      } else if (e.key === "ArrowDown") {
        e.preventDefault();
        setActiveIndex((prevIndex) =>
          Math.min(prevIndex + 1, results.length - 1)
        );
      } else if (e.key === "Enter" && activeIndex !== -1) {
        e.preventDefault();
        navigate(`/products/${results[activeIndex].id}`);
        clearInput();
      } else if (e.key === "Escape" && searchInput === "") {
        e.preventDefault();
        (document.activeElement as HTMLElement).blur();
      } else if (e.key === "Escape") {
        e.preventDefault();
        clearInput();
      }
    },
    [activeIndex, results, clearInput, navigate, searchInput]
  );

  //rerender index call after index changed
  useEffect(() => {
    if (ulRef.current && activeIndex !== -1) {
      const lis = ulRef.current.querySelectorAll("li");
      if (lis.length > 0) {
        lis[activeIndex]?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  }, [activeIndex]);

  useEffect(() => {
    window.addEventListener("keydown", handleArrowNavigation);
    return () => {
      window.removeEventListener("keydown", handleArrowNavigation);
    };
  }, [handleArrowNavigation]);

  //researching after input has changed with 600ms delay, to avoid multiple SQL calls
  useEffect(() => {
    if (searchInput !== "") {
      const search = setTimeout(() => {
        fetchProducts(searchInput);
      }, 600);
      return () => clearTimeout(search);
    }
  }, [searchInput]);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Form.Control
          className="input-field"
          type="text"
          onChange={onUpdateField}
          value={searchInput}
          placeholder={t("searchbar")}
          id="searchBar"
          autoComplete="off"
        />
        <div className="hint-box" tabIndex={0}>
          Mit "/" direkt in die Suche springen
        </div>
        {searchInput !== "" && results.length > 0 ? (
          <ul
            className="ul-scrollable"
            style={{ zIndex: 1000 }}
            tabIndex={-1}
            ref={ulRef}
          >
            {results.map((result, index) => (
              <Link
                key={result.id}
                to={`/products/${result.id}`}
                tabIndex={index === activeIndex ? 0 : -1}
                onClick={() => {
                  clearInput();
                }}
              >
                <li
                  tabIndex={-1}
                  className={index === activeIndex ? "selected-item" : ""}
                >
                  <b>{result.number}</b> {result.description.title}
                  <hr />
                </li>
              </Link>
            ))}
          </ul>
        ) : (
          <></>
        )}
      </Form>
    </>
  );
}
