import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import { ContactPersonSearch } from "./ContactPersonSearch";

export function ContactPersonPage() {
    return (
    <main>
      <section>
        <Container className="container">
          <Row className="mb-5">
            <ContactPersonSearch />
          </Row>
        </Container>
      </section>
    </main>
  );
}
