import { Container, Image } from "react-bootstrap";
import Agb from "assets/logos/agb.jpg";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { AttachmentGroups } from "types/downloads";
import { fetchAgb } from "services/downloads";
import { toast } from "react-toastify";

export function AGB() {
  const [agbs, setAgbs] = useState<AttachmentGroups[]>();

  const getAgb = async () => {
    try {
      const agb: AttachmentGroups[] = await fetchAgb();
      setAgbs(agb);
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : (err as string);
      toast.error(errorMessage);
    }
  };

  useEffect(() => {
    getAgb();
  }, []);

  const { t } = useTranslation();
  return (
    <main>
      <section>
        <Container>
          <Image src={Agb} className="img-agb" />
          <h3>{t("agb")}</h3>
          <p>{t("agb.1")}</p>
          <p>{t("agb.1.text")}</p>
          <p>{t("agb.2")}</p>
          <p>{t("agb.2.1")}</p>
          <p>{t("agb.2.2")}</p>
          <p>{t("agb.2.3")}</p>
          <p>{t("agb.3")}</p>
          <p>{t("agb.3.1")}</p>
          <p>{t("agb.3.2")}</p>
          <p>{t("agb.3.2.1")}</p>
          <p>{t("agb.3.2.2")}</p>
          <p>{t("agb.3.2.3")}</p>
          <p>{t("agb.4")}</p>
          <p>{t("agb.4.1")}</p>
          <p>{t("agb.4.2")}</p>
          <p>{t("agb.4.3")}</p>
          <p>{t("agb.4.4")}</p>
          <p>{t("agb.4.5")}</p>
          <p>{t("agb.4.6")}</p>
          <p>{t("agb.4.7")}</p>
          <p>{t("agb.4.8")}</p>
          <p>{t("agb.5")}</p>
          <p>{t("agb.5.1")} </p>
          <p>{t("agb.5.2")}</p>
          <p>{t("agb.5.3")}</p>
          <p>{t("agb.5.4")}</p>
          <p>{t("agb.5.5")}</p>
          <p>{t("agb.5.6")}</p>
          <p>{t("agb.5.7")}</p>
          <p>{t("agb.6")}</p>
          <p>{t("agb.6.1")}</p>
          <p>{t("agb.6.2")}</p>
          <p>{t("agb.6.3")}</p>
          <p>{t("agb.6.4")}</p>
          <p>{t("agb.6.5")}</p>
          <p>{t("agb.6.6")}</p>
          <p>{t("agb.6.7")}</p>
          <p>{t("agb.6.8")}</p>
          <p>{t("agb.7")}</p>
          <p>{t("agb.7.1")}</p>
          <p>{t("agb.7.1.text")}</p>
          <p>{t("agb.7.2")}</p>
          <p>{t("agb.8")}</p>
          <p>{t("agb.8.1")}</p>
          <p>{t("agb.8.1.opener")}</p>
          <p>{t("agb.8.1.a")}</p>
          <p>{t("agb.8.1.a.text")}</p>
          <p>{t("agb.8.1.b")}</p>
          <p>{t("agb.8.1.c")}</p>
          <p>{t("agb.8.1.d")}</p>
          <p>{t("agb.8.1.e")}</p>
          <p>{t("agb.8.1.text")}</p>
          <p>{t("agb.8.2")}</p>
          <p>{t("agb.8.3")}</p>
          <p>{t("agb.8.3.a")}</p>
          <p>{t("agb.8.3.b")}</p>
          <p>{t("agb.8.3.c")}</p>
          <p>{t("agb.8.4")}</p>
          <p>{t("agb.8.4.a")}</p>
          <p>{t("agb.8.4.b")}</p>
          <p>{t("agb.8.4.c")}</p>
          <p>{t("agb.8.4.text")}</p>
          <p>{t("agb.8.5")}</p>
          <p>{t("agb.8.6")}</p>
          <p>{t("agb.8.7")}</p>
          <p>{t("agb.8.7.opener")}</p>
          <p>{t("agb.8.7.a")}</p>
          <p>{t("agb.8.7.b")}</p>
          <p>{t("agb.8.7.c")}</p>
          <p>{t("agb.8.7.closer")}</p>
          <p>{t("agb.8.8")}</p>
          <p>{t("agb.8.8.a")}</p>
          <p>{t("agb.8.8.b")}</p>
          <p>{t("agb.8.8.c")}</p>
          <p>{t("agb.8.8.d")}</p>
          <p>{t("agb.8.8.e")}</p>
          <p>{t("agb.8.8.f")}</p>
          <p>{t("agb.8.9")}</p>
          <p>{t("agb.9")}</p>
          <p>{t("agb.9.1")}</p>
          <p>{t("agb.9.2")}</p>
          <p>{t("agb.9.3")}</p>
          <p>{t("agb.10")}</p>
          <p>{t("agb.11")}</p>
          <p>{t("agb.closer")}</p>
          <ul>
            {agbs?.map((agb) =>
              agb.attachments.map((a) => (
                <li
                  className="attachment"
                  onClick={() => window.open(a.filepath)}
                  key={agb.id}
                >
                  {a.names[0].title}
                </li>
              ))
            )}
          </ul>
        </Container>
      </section>
    </main>
  );
}
