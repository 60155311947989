import { Row } from "react-bootstrap";
import { CompanyInformationCard } from "types/companyInformation";
import CountUp from "react-countup";

interface CompanyInformationCardListItemProps {
  companyInformation: CompanyInformationCard;
}

export function CompanyInformationCardListItem({
  companyInformation,
}: CompanyInformationCardListItemProps) {
  return (
    <div className="text-center  company-information-card">
      <Row>
        <b className="count-up" data-val={companyInformation.count}>
          <CountUp
            end={companyInformation.count}
            duration={3}
            enableScrollSpy={true}
          />
        </b>
      </Row>
      <Row>
        <span>{companyInformation.detail}</span>
      </Row>
    </div>
  );
}
