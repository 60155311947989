import Navbar from "react-bootstrap/Navbar";
import Image from "react-bootstrap/Image";
import logo from "assets/logos/mueba_logo.png";
import Button from "react-bootstrap/Button";
import NavLink from "components/routing/NavLink";
import Container from "react-bootstrap/Container";
import { NavDropdown } from "react-bootstrap";
import { LanguageSwitcher } from "components/i18next";
import { SearchBar } from "./SearchBar";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { NavDropDownSubmenus } from "components/NavbarDropdown/NavbarDropdown";
import { NavDropDownDownloadsSubmenus } from "components/NavbarDownloadsDropdown/NavbarDownloadsDropdown";
import { Link, useLocation } from "react-router-dom";

export function AppNavbar() {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  {/**  const location = useLocation(); // check hash location

  // function, to check if hash is active 
   
  const isActive = (hash: string) => {
    return location.hash === `#${hash}` ? "active" : ""; // compare latest hash
  };
  */}

  return (
    <Navbar fixed="top" expand="md" expanded={expanded} className="navbar-text">
      <Container>
        <Navbar.Brand>
          <NavLink to={{ name: "Home" }}>
            <Image
              src={logo}
              alt="logo"
              style={{ height: "40px", width: "100px", objectFit: "contain" }}
            />
          </NavLink>
        </Navbar.Brand>
        <Navbar.Brand>
          <LanguageSwitcher />
        </Navbar.Brand>
        <Navbar.Toggle
          id="basic-navbar-nav"
          onClick={() => setExpanded(!expanded)}
        />

        <Navbar.Collapse id="basic-navbar-nav">
          <Navbar.Brand className="mx-4" onClick={() => setExpanded(false)}>
            <NavLink to={{ name: "About" }}>
              <h6 className="my-1">{t("nav.about")}</h6>
            </NavLink>
          </Navbar.Brand>

          <Navbar.Brand>
            <NavDropDownSubmenus onClick={() => setExpanded(false)} />
          </Navbar.Brand>

          <Navbar.Brand>
            <SearchBar onClick={() => setExpanded(false)} />
          </Navbar.Brand>

          <NavDropdown title={t("nav.contactperson")} className="my-1 ">
            <NavDropdown.Item
              eventKey="fieldService"
              onClick={() => setExpanded(false)}
              as="span"
            >
              <NavLink to={{ name: "ContactPerson" }}>
                <h6 className="my-1 nav-font-size">
                  {t("nav.contactperson.fieldservice")}
                </h6>
              </NavLink>
            </NavDropdown.Item>
            <NavDropdown.Item
              eventKey="management"
              as="span"
              onClick={() => setExpanded(false)}
            >
              <NavLink to={{ name: "Management" }}>
                <h6 className="my-1">{t("nav.contactperson.management")}</h6>
              </NavLink>
            </NavDropdown.Item>
            <NavDropdown.Item
              eventKey="theTeam"
              onClick={() => setExpanded(false)}
              as="span"
            >
              <NavLink to={{ name: "Team" }}>
                <h6 className="my-1">{t("nav.contactperson.theteam")}</h6>
              </NavLink>
            </NavDropdown.Item>
          </NavDropdown>

          { /** 

       
          <Navbar.Brand className="mx-4">
            <NavDropdown title={t("nav.downloads")} className="my-1">
         
              <NavDropdown.Item onClick={() => setExpanded(false)} as="span">
                <Link to="/attachment#brochures" className={isActive("brochures")}>
                  <h6 className="my-1">{t("nav.downloads.brochures")}</h6>
                </Link>
              </NavDropdown.Item>

           
              <NavDropdown.Item onClick={() => setExpanded(false)} as="span">
                <Link to="/attachment#new-items" className={isActive("new-items")}>
                  <h6 className="my-1">{t("nav.downloads.new-items")}</h6>
                </Link>
              </NavDropdown.Item>

        
              <NavDropdown.Item onClick={() => setExpanded(false)} as="span">
                <Link to="/attachment#certificates" className={isActive("certificates")}>
                  <h6 className="my-1">{t("nav.downloads.certificates")}</h6>
                </Link>
              </NavDropdown.Item>

        
              <NavDropdown.Item onClick={() => setExpanded(false)} as="span">
                <Link to="/attachment#manuals" className={isActive("manuals")}>
                  <h6 className="my-1">{t("nav.downloads.manuals")}</h6>
                </Link>
              </NavDropdown.Item>
            </NavDropdown>
          </Navbar.Brand>
        

          */}

          {/* Downloads */}
          <Navbar.Brand className="mx-4">
          <NavDropdown title={t("nav.downloads")} className="my-1 ">
         
          {/* brochures */}
            <NavDropdown.Item
              eventKey="brochures"
              onClick={() => setExpanded(false)}
              as="span"
            >
              <NavLink to={{ name: "DownloadsBrochures" }}>
                <h6 className="my-1 nav-font-size">
                  {t("nav.downloads.brochures")}
                </h6>
              </NavLink>
            </NavDropdown.Item>

          {/* new-items*/}
            <NavDropdown.Item
              eventKey="new-items"
              as="span"
              onClick={() => setExpanded(false)}
            >
              <NavLink to={{ name: "DownloadsNewItems" }}>
                <h6 className="my-1">{t("nav.downloads.new-items")}</h6>
              </NavLink>
            </NavDropdown.Item>

          {/*certificates */}
            <NavDropdown.Item
              eventKey="certificates"
              onClick={() => setExpanded(false)}
              as="span"
            >
              <NavLink to={{ name: "DownloadsCertificates" }}>
                <h6 className="my-1">{t("nav.downloads.certificates")}</h6>
              </NavLink>
            </NavDropdown.Item>

            {/*manuals*/}
            <NavDropdown.Item
              eventKey="manuals"
              onClick={() => setExpanded(false)}
              as="span"
            >
              <NavLink to={{ name: "DownloadsManuals" }}>
                <h6 className="my-1">{t("nav.downloads.manuals")}</h6>
              </NavLink>
            </NavDropdown.Item>
          
          </NavDropdown>
          </Navbar.Brand>


          <Navbar.Brand className="mx-4" onClick={() => setExpanded(false)}>
            <NavLink to={{ name: "Career" }}>
              <h6 className="my-1">{t("nav.career")}</h6>
            </NavLink>
          </Navbar.Brand>

          <Navbar.Brand className="mx-4" onClick={() => setExpanded(false)}>
            <NavLink to={{ name: "Contact" }}>
              <Button variant="danger" className="my-3 ">
                {t("contact")}
              </Button>
            </NavLink>
          </Navbar.Brand>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
