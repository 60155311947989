import {ProductDetailsListItem} from "./ProductDetailsListItem";
import {ArticleProduct} from "../../types/articleProduct";

export interface ProductDetailsListProps {
    productDetails: ArticleProduct;
}

export function ProductDetailsList ({productDetails}: ProductDetailsListProps){
    return (
        <ProductDetailsListItem productDetail={productDetails} />
    )
}