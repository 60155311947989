//React
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

//I18Next
import "lib/i18next";

//Router
import { BrowserRouter as Router } from "react-router-dom";

//Bootstrap
import "bootstrap/dist/css/bootstrap.min.css";

// React toastify
import "react-toastify/dist/ReactToastify.css";

//Global Custom Styles
import "./index.css";
import { ScrollToTop } from "components/app/ScrollToTop";

//AOS
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init({
  once: true,
  duration: 1000,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Router>
      <ScrollToTop />
      <App />
    </Router>
  </React.StrictMode>
);
