import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import { convertCharacter } from "services/convertCharacter";

export function PrivacyPolicy() {
  const { t } = useTranslation();
  return (
    <main>
      <section>
        <Container>
          <h2>{t("privacy.policy.header")}</h2>
          <h3>{t("privacy.policy.responsible")}</h3>
          <p> Müller & Baum GmbH & Co. KG</p>
          <p>Birkenweg 52/59846 Sundern</p>
          <p>{convertCharacter(t("privacy.policy.info"))}</p>
          <br />
          <p>{t("privacy.policy.1")} </p>
          <p>{convertCharacter(t("privacy.policy.contact"))}</p>
          <h3>{t("privacy.policy.2.header")}</h3>
          <p>{t("privacy.policy.2")}</p>
          <h3>{t("privacy.policy.3.header")}</h3>
          <p>{t("privacy.policy.3")}</p>
          <h3>{t("privacy.policy.4.header")}</h3>
          <p>{t("privacy.policy.4")}</p>
          <h3> {t("privacy.policy.5.header")}</h3>
          <p>{convertCharacter(t("privacy.policy.5"))}</p>
        </Container>
      </section>
    </main>
  );
}
