import { ReactNode } from "react";
import { NavLink as ReactRouterNavLink } from "react-router-dom";
import { getRouteByName } from "router/index";
import { LinkTo } from "types/routing";

export interface NavLinkProps {
  className?: string;
  to: LinkTo;
  children: ReactNode;
}

function NavLink({ className, to, children }: NavLinkProps) {
  let href = "/";
  if (typeof to === "string") {
    href = to;
  } else {
    const route = getRouteByName(to.name);
    if (route) {
      href = route.path;
    }
  }

  return (
    <ReactRouterNavLink to={href} className={className}>
      {children}
    </ReactRouterNavLink>
  );
}

export default NavLink;
