import { ArticleGroup } from "types/articleGroup";
import { GroupsListItem } from "./GroupsListItem";

export interface GroupListProps {
  group: ArticleGroup ;
}

export function GroupList({ group }: GroupListProps) {
  return <GroupsListItem group={group} />;
}
