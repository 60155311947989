// Components
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

// Hooks
import React, { useState, useMemo } from "react";

// Libs
import { axios, APIResponse } from "lib/axios";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faSquarePhone } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { convertCharacter } from "services/convertCharacter";
import { ContactIcons } from "./ContactIcons";








interface Form {
  fullName: string;
  country: string;
  postCode: string;
  email: string;
  message: string;
  phoneNumber: string;
  city: string;
  category: string;
  companyName: string | undefined;
}

export function ContactForm() {
  const { t } = useTranslation();
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [form, setForm] = useState<Form>({
    fullName: "",
    country: "",
    postCode: "",
    email: "",
    message: "",
    phoneNumber: "",
    city: "",
    category: "",
    companyName: undefined,
  });

  const fieldEmpty = useMemo(() => {
    return !(
      form.postCode !== "" &&
      form.country !== "" &&
      form.email !== "" &&
      form.fullName !== "" &&
      form.category !== "" &&
      (form.category === "privateCustomer" ||
        (form.companyName !== "" && form.companyName !== undefined)) &&
      form.city !== "" &&
      form.phoneNumber !== ""
    );
  }, [form]);

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();

      await axios.post<APIResponse>("/contactform/send", form);
      toast.success("Erfolgreich Kontaktiert");
      setForm({
        fullName: "",
        country: "",
        postCode: "",
        email: "",
        message: "",
        phoneNumber: "",
        city: "",
        category: "",
        companyName: undefined,
      });
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : (err as string);
      toast.error(errorMessage);
    }
  };

  const onUpdateField = (
    event: React.ChangeEvent<HTMLInputElement & HTMLSelectElement>
  ) => {
    const nextFormState = {
      ...form,
      [event.target.name]: event.target.value,
    };
    if (nextFormState.category === "privateCustomer") {
      nextFormState.companyName = undefined;
    }
    setForm(nextFormState);
  };

  const togglePrivacyPolice = () => {
    setPrivacyPolicy((current) => !current);
  };

  const allowOnlyNumbers = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!/[0-9]|Backspace/.test(e.key)) {
      e.preventDefault();
    }
  };



  return (

    <Container className="mt-5">
      <Row data-aos="fade-down mt-5">
        <Col md="6" className="mt-5 d-flex flex-column justify-content-between">
          <h3>Müller & Baum GmbH & Co. KG</h3>
          <p>Birkenweg 52 - DE-59846 Sundern-Hachen</p>
          <p style={{ fontWeight: "bold" }}>
            <FontAwesomeIcon icon={faSquarePhone} />
            <a
              className="none-decoration text-black"
              style={{ fontWeight: "normal" }}
              href="tel:029358010"
            >
              {" "}
              +49 (2935) 801-0
            </a>{" "}
          </p>

          <p style={{ fontWeight: "bold" }}>
            <FontAwesomeIcon icon={faEnvelope} />
            <a
              style={{ fontWeight: "normal", color: "#990000" }}
              href="mailto:service@mueba.de"
            >
              {" "}
              service@mueba.de
            </a>
          </p>
          <div className="mt-auto">
            <ContactIcons />
          </div>
        </Col>
        <Col md="6" className="mt-5">
          <h3> {t("contact.hint")}</h3>
          <h2 className="contact-form-title">{t("contact")}</h2>
          <Form onSubmit={handleFormSubmit}>
            <Form.Group className="mb-1" controlId="category">
              <Form.Select
                id="category"
                aria-label="Gruppe"
                name="category"
                className="form"
                onChange={onUpdateField}
                value={form.category}
              >
                <option value="" disabled>
                  -
                </option>
                <option value="dealer">{t("contact.form.dealer")}</option>
                <option value="other">{t("contact.form.other")}</option>
                <option value="privateCustomer">
                  {t("contact.form.private")}
                </option>
              </Form.Select>
            </Form.Group>
            {form.category !== "privateCustomer" && form.category !== "" ? (
              <Form.Group className="mb-1" controlId="companyName">
                <Form.Control
                  type="text"
                  aria-label="Company Name"
                  name="companyName"
                  className="form"
                  placeholder={`${t("contact.companyname")}*`}
                  value={form.companyName || ""}
                  onChange={onUpdateField}
                />
              </Form.Group>
            ) : (
              <></>
            )}
            <Form.Group className="mb-1" controlId="formForLastName">
              <Form.Control
                type="text"
                aria-label="Fullname"
                className="form"
                name="fullName"
                placeholder={`${t("contact.form.name")}*`}
                value={form.fullName}
                onChange={onUpdateField}
              />
            </Form.Group>
            <Form.Group className="mb-1">
              <Row className="mb-0">
                <Col md="3" className="mb-0">
                  <Form.Control
                    type="text"
                    aria-label="Country"
                    className="form"
                    name="country"
                    placeholder={`${t("contact.form.country")}*`}
                    value={form.country}
                    onChange={onUpdateField}
                  />
                </Col>
                <Col md="3" className="mb-0">
                  <Form.Control
                    type="number"
                    name="postCode"
                    className="form"
                    placeholder={`${t("contact.form.postcode")}*`}
                    onKeyDown={allowOnlyNumbers}
                    value={form.postCode}
                    onChange={onUpdateField}
                  />
                </Col>
                <Col md="3" className="mb-0">
                  <Form.Control
                    type="text"
                    name="city"
                    className="form"
                    placeholder={`${t("contact.form.city")}*`}
                    value={form.city}
                    onChange={onUpdateField}
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-1" controlId="formEmail">
              <Form.Control
                type="email"
                arial-label="Email field"
                name="email"
                className="form"
                placeholder={`${t("contact.form.email")}*`}
                value={form.email}
                onChange={onUpdateField}
              />
            </Form.Group>
            <Form.Group className="mb-1" controlId="formPhoneNumber">
              <Form.Control
                type="tel"
                arial-label="Phone Number"
                name="phoneNumber"
                className="form"
                placeholder={`${t("contact.form.phone")}*`}
                value={form.phoneNumber}
                onChange={onUpdateField}
              />
            </Form.Group>
            <Form.Group className="mb-1" controlId="formMessage">
              <Form.Control
                as="textarea"
                rows={4}
                className="form mb-1"
                name="message"
                value={form.message}
                onChange={onUpdateField}
                placeholder={t("contact.form.message")}
              />
              {t("contact.information.text")}
            </Form.Group>
            {!fieldEmpty && (
              <Form.Check
                type="checkbox"
                label={convertCharacter(t("contact.form.button.hint"))}
                checked={privacyPolicy}
                onChange={togglePrivacyPolice}
              />
            )}
            <Button
              disabled={fieldEmpty || !privacyPolicy}
              variant="danger"
              type="submit"
              className="my-4"
            >
              {t("contact.form.button")}
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}
