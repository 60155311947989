import { ArticleCategoryList } from "components/Article/Categories";
import { useEffect, useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import { Navigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { fetchArticleCategoryById } from "services/articleGroups";
import { ArticleCategoryInterface } from "types/articleCategory";

export function ArticleCategory() {
  const [categoryById, setCategoryById] = useState<ArticleCategoryInterface>();
  const { id } = useParams();

  const getCategoryById = async (id: number) => {
    try {
      const category: ArticleCategoryInterface = await fetchArticleCategoryById(
        id
      );
      setCategoryById(category);
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : (err as string);
      toast.error(errorMessage);
    }
  };
  useEffect(() => {
    const parsedId = parseInt(id || "");

    if (isNaN(parsedId)) {
      <Navigate to="/" />;
      return;
    }
    getCategoryById(parsedId);
  }, [id]);

  return (
    <main>
      <Container>
        {categoryById !== undefined ? (
          <ArticleCategoryList category={categoryById} />
        ) : (
          <Spinner animation="border" />
        )}
      </Container>
    </main>
  );
}
