import { Card, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

export function Management() {
  const { t } = useTranslation();
  const management = [
    {
      name: "Joachim Müller",
      position: t("contactperson.management.title.two"),
      mail: "j.mueller@mueba.de",
    },
    {
      name: "Jürgen Schäfer",
      position: t("contactperson.management.title.two"),
      mail: "j.schaefer@mueba.de",
    },
    {
      name: "Thomas Baum",
      position: t("contactperson.management.advisory"),
      mail: "th.baum@mueba.de",
    },
    {
      name: "Michael Müller",
      position: t("contactperson.management.technical"),
      mail: "m.mueller@mueba.de",
    },
  ];

  return (
    <main>
      <section style={{ height: "85vh" }}>
        <Container>
          <h1 className="text-center mb-5 d-none d-xl-block">
            {t("contactperson.management.title")}
          </h1>
          <h2 className="text-center mb-5  d-sm-block d-xl-none">
            {t("contactperson.management.title")}
          </h2>
          <Row>
            {management.map((m) => (
              <Col md={3} key={m.name} className="text-center">
                <Card className="mb-2">
                  {m.name}
                  <b>{m.position}</b>
                  <hr />
                  <a className="text-black my-2" href={`mailto:${m.mail}`}>
                    {m.mail}{" "}
                  </a>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </main>
  );
}
