import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React from "react";

//Libs
import { APIResponse, axios } from "lib/axios";
import { toast } from "react-toastify";

//Hooks
import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { convertCharacter } from "services/convertCharacter";

interface Form {
  firstname: string;
  lastname: string;
  phoneNumber: string;
  email: string;
  subject: string;
  resume: string;
  application: string;
}

export function CareerContact() {
  const { t } = useTranslation();
  const [form, setForm] = useState<Form>({
    firstname: "",
    lastname: "",
    phoneNumber: "",
    email: "",
    subject: "",
    resume: "",
    application: "",
  });
  const [privacyPolicy, setPrivacyPolicy] = useState(false);

  const fieldEmpty = useMemo(() => {
    return !(
      form.firstname !== "" &&
      form.lastname !== "" &&
      form.phoneNumber !== "" &&
      form.email !== "" &&
      form.subject !== "" &&
      form.resume !== "" &&
      form.application !== ""
    );
  }, [form]);

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      const formElement = event.target as HTMLFormElement;
      const formData = new FormData(formElement);

      await axios.post<APIResponse>("/contactForm/application", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success("Success");
      setForm({
        firstname: "",
        lastname: "",
        phoneNumber: "",
        email: "",
        subject: "",
        resume: "",
        application: "",
      });
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : (err as string);
      toast.error(errorMessage);
    }
  };

  const onUpdateField = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nextFormState = {
      ...form,
      [event.target.name]: event.target.value,
    };
    setForm(nextFormState);
  };

  const togglePrivacyPolice = () => {
    setPrivacyPolicy((current) => !current);
  };

  return (
    <Form onSubmit={handleFormSubmit}>
      <Form.Group className="mb-1" controlId="firstName">
        <Form.Label>
          {t("user.firstname")}
          <span className="red-star">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          aria-label={t("user.firstname")}
          name="firstname"
          value={form.firstname}
          onChange={onUpdateField}
        />
      </Form.Group>
      <Form.Group className="mb-1" controlId="lastName">
        <Form.Label>
          {t("user.lastname")}
          <span className="red-star">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          aria-label={t("user.lastname")}
          name="lastname"
          value={form.lastname}
          onChange={onUpdateField}
        />
      </Form.Group>
      <Form.Group className="mb-1" controlId="phonenumber">
        <Form.Label>{t("user.phonenumber")}</Form.Label>
        <Form.Control
          type="number"
          aria-label={t("user.phonenumber")}
          name="phoneNumber"
          value={form.phoneNumber}
          onChange={onUpdateField}
        />
      </Form.Group>
      <Form.Group className="mb-1" controlId="email">
        <Form.Label>
          {t("user.email")} <span className="red-star">*</span>
        </Form.Label>
        <Form.Control
          type="email"
          aria-label={t("user.email")}
          name="email"
          value={form.email}
          onChange={onUpdateField}
        />
      </Form.Group>
      <Form.Group className="mb-1" controlId="subject">
        <Form.Label>{t("user.subject")}</Form.Label>
        <Form.Control
          type="text"
          aria-label={t("user.subject")}
          name="subject"
          value={form.subject}
          onChange={onUpdateField}
        />
      </Form.Group>
      <Form.Group className="mb-1" controlId="application">
        <Form.Label>{t("user.jobapplication")}</Form.Label>
        <Form.Control
          type="file"
          accept="application/pdf"
          aria-label={t("user.jobapplication")}
          name="application"
          value={form.application}
          onChange={onUpdateField}
        />
      </Form.Group>
      <Form.Group className="mb-1" controlId="Resume">
        <Form.Label>{t("user.cv")}</Form.Label>
        <Form.Control
          type="file"
          accept=".pdf"
          aria-label={t("user.cv")}
          name="resume"
          value={form.resume}
          onChange={onUpdateField}
        />
      </Form.Group>
      <Form.Group>
        {!fieldEmpty && (
          <Form.Check
            type="checkbox"
            label={convertCharacter(t("career.dataagree"))}
            checked={privacyPolicy}
            onChange={togglePrivacyPolice}
          />
        )}
      </Form.Group>
      <Button
        disabled={fieldEmpty || !privacyPolicy}
        variant="danger"
        type="submit"
        className="mt-3"
      >
        {t("user.submit")}
      </Button>
    </Form>
  );
}
