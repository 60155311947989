import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";

import funken from "assets/newImages/Funken.jpg";
import helm from "assets/newImages/Helm.jpg";
import karrengestell from "assets/newImages/Karrengestelle.jpg";
import laserBiegemaschine from "assets/newImages/LaserBiegemaschine.jpg";
import maschineProduktion from "assets/newImages/MaschineProduktion.jpg";
import montiermuldenkarre from "assets/newImages/MontierenMuldenkarre.jpg";
import lkwoffenePlane from "assets/newImages/muebaLKWoffenePlane.jpeg";
import muldenkarre from "assets/newImages/Muldenkarre.jpg";
import neueschweissMaschine from "assets/newImages/neueSchweissMaschine.jpg";
import roboter from "assets/newImages/Roboter.jpg";
import roboter2 from "assets/newImages/RoboterBiegemaschine_hoch.jpg";
import roboter3 from "assets/newImages/RoboterBiegemaschine_quer.jpg";
import rohr2 from "assets/newImages/Rohr_in_Maschine.jpg";
import rohr from "assets/newImages/Rohr.jpg";
import saege from "assets/newImages/Saege.jpg";
import schneider from "assets/newImages/Schneider.jpg";
import schubkarren from "assets/newImages/Schubkarren.jpg";
import schweissen from "assets/newImages/Schweissen.jpg";
import schweisserei from "assets/newImages/Schweisserei.jpg";
import schweissvorgang from "assets/newImages/Schweissvorgang.png";
import schubkarreNew from "assets/aboutUs/Boemer_Schubkarre.jpg";
import schweisserei2 from "assets/newImages/schweisserei_2.png";

import Link from "components/routing/Link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComments,
  faHandshake,
  faPhone,
  faTruckFast,
} from "@fortawesome/free-solid-svg-icons";
import { Carousel } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { convertCharacter } from "services/convertCharacter";

const production = [
  {
    image: funken,
  },
  {
    image: helm,
  },
  {
    image: karrengestell,
  },
  {
    image: laserBiegemaschine,
  },
  {
    image: maschineProduktion,
  },
  {
    image: muldenkarre,
  },
  {
    image: roboter2,
  },
  {
    image: roboter3,
  },
  {
    image: schneider,
  },
  {
    image: schubkarren,
  },
  {
    image: schweisserei2,
  },
  {
    image: montiermuldenkarre,
  },
  {
    image: schweissvorgang,
  },
  {
    image: neueschweissMaschine,
  },
  {
    image: roboter,
  },
  {
    image: rohr,
  },
  {
    image: rohr2,
  },
  {
    image: schweissen,
  },
  {
    image: lkwoffenePlane,
  },
  {
    image: schweisserei,
  },
];

export function About() {
  const { t } = useTranslation();

  return (
    <main>
      <section className="section-secondary">
        <Container>
          <Row>
            <h1 className="text-center">{t("about")}</h1>
            <Col md="5" className="mt-5 pt-2" data-aos="fade-up">
              <Image
                src={funken}
                style={{
                  aspectRatio: "1 / 1",
                  objectFit: "cover",
                }}
              />
            </Col>
            <Col md="6" className="mt-5">
              <h2 className="px-5" data-aos="fade-down">
                {t("about.tradition")}
              </h2>
              <hr />
              <div className="mx-5">
                <span data-aos="fade-up">
                  <ul className="px-3 mx-1" data-aos="fade-up">
                    <li>{t("about.tradition.list.first")}</li>
                    <li>{t("about.tradition.list.second")}</li>
                    <li>{t("about.tradition.list.third")}</li>
                    <li>{t("about.tradition.list.fourth")}</li>
                    <li>{t("about.tradition.list.fifth")}</li>
                  </ul>
                  <b>{t("about.tradition.text.opener")}</b>
                  <br />
                  {t("about.tradition.text")}
                </span>
                <p data-aos="fade-up">
                  {convertCharacter(t("about.closer.text"))}
                </p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="6" className="mt-5 ">
              <h2 className="px-4" data-aos="fade-down">
                {t("about.administration")}
              </h2>
              <hr />
              <p className="px-4" data-aos="fade-up">
                {t("about.administration.opener.text")}
              </p>
              <ul
                className="px-4 d-none d-xl-block"
                style={{ listStyleType: "none", fontSize: "13px" }}
                data-aos="fade-up"
              >
                <li className="m-2 align-items-center">
                  <FontAwesomeIcon icon={faPhone} size="2x" />{" "}
                  <span className="mx-1 px-1">
                    {t("about.administration.list.first")}
                  </span>
                </li>
                <li className="m-2">
                  <FontAwesomeIcon icon={faTruckFast} size="2x" />
                  <span className="mx-1">
                    {t("about.administration.list.second")}
                  </span>
                </li>
                <li className="m-2">
                  <FontAwesomeIcon icon={faComments} size="2x" />
                  <span className="mx-1">
                    {t("about.administration.list.third")}
                  </span>
                </li>
                <li className="m-2">
                  <FontAwesomeIcon icon={faHandshake} size="2x" />
                  <span className="mx-1">
                    {t("about.administration.list.fourth")}
                  </span>
                </li>
              </ul>
              <ul
                className="px-4 d-sm-block d-xl-none"
                style={{ listStyleType: "none", fontSize: "11px" }}
                data-aos="fade-up"
              >
                <li className="m-2 align-items-center">
                  <FontAwesomeIcon icon={faPhone} size="2x" />{" "}
                  <span className="mx-1 px-1">
                    {t("about.administration.list.first")}
                  </span>
                </li>
                <li className="m-2">
                  <FontAwesomeIcon icon={faTruckFast} size="2x" />
                  <span className="mx-1">
                    {t("about.administration.list.second")}
                  </span>
                </li>
                <li className="m-2">
                  <FontAwesomeIcon icon={faComments} size="2x" />
                  <span className="mx-1">
                    {t("about.administration.list.third")}
                  </span>
                </li>
                <li className="m-2">
                  <FontAwesomeIcon icon={faHandshake} size="2x" />
                  <span className="mx-1">
                    {t("about.administration.list.fourth")}
                  </span>
                </li>
              </ul>
            </Col>
            <Col md="6" className="p-5" data-aos="fade-down">
              {/*<Image src={dispo} style={{ objectFit: "cover" }} />*/}
            </Col>
          </Row>
          <Row data-aos="fade-up">
            <Col md="12" className="mt-5" data-aos="fade-up">
              <h2 className="text-center">{t("about.production")}</h2>
              <hr />
              <p className="mb-5 mx-4 text-center">
                {t("about.production.text")}
              </p>
              <Carousel interval={2000}>
                {production.map((p) => (
                  <Carousel.Item key={p.image}>
                    <Image
                      src={p.image}
                      style={{
                        aspectRatio: 16 / 9,
                        objectFit: "cover",
                        height: "500px",
                      }}
                    />
                  </Carousel.Item>
                ))}
              </Carousel>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section-secondary">
        <Container>
          <Row>
            <Col md="4">
              <h2 data-aos="fade-down">
                {t("about.career.bring.your")}{" "}
                <span className="text-danger">{t("about.career")}</span>{" "}
                {t("about.career.rolling")}
              </h2>
              <p data-aos="fade-up">
                {convertCharacter(t("about.career.text"))}
              </p>
              <Link to={{ name: "Career" }} data-aos="fade-up">
                <Button variant="danger">
                  {t("about.career.application.button")}
                </Button>
              </Link>
            </Col>
            <Col md="6" data-aos="fade-down">
              <Image
                src={schubkarreNew}
                style={{
                  height: "500px",
                  objectFit: "contain",
                }}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
}
