import Container from "react-bootstrap/Container";

import { ContactForm } from "components/contact/ContactForm";
import { Product } from "types/products";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Col, Row } from "react-bootstrap";
import { ArticleCategoryInterface } from "types/articleCategory";
import { fetchArticleCategories } from "services/articleCategories";
import { ProductCard } from "components/card/ProductCard";
import { useTranslation } from "react-i18next";

export interface WheelbarrowProps {
  wheelbarrows: Product;
}

export function Products() {
  const { t } = useTranslation();
  const [articleCategories, setArticleCategories] = useState<
    ArticleCategoryInterface[]
  >([]);

  useEffect(() => {
    getArticleCategories();
  }, []);

  const getArticleCategories = async () => {
    try {
      const articleCategories: ArticleCategoryInterface[] =
        await fetchArticleCategories();
      setArticleCategories(articleCategories);
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : (err as string);
      toast.error(errorMessage);
    }
  };

  return (
    <main>
      <section className="section-secondary">
        <Container>
          <h1 className="text-center d-none d-xl-block">
            {t("home.deliveryProgramm")}
          </h1>
          <h2 className="text-center d-xl-none">
            {t("home.deliveryProgramm")}
          </h2>
          <hr />
          <Row>
            {articleCategories.map((ac) => (
              <Col md="6" data-aos="fade-down" key={ac.id}>
                <ProductCard productCard={ac} />
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <section className="section-secondary">
        <ContactForm />
      </section>
    </main>
  );
}
