import { RouterRoute } from "types/routing";
import { Route } from "react-router-dom";

export function createRoutes(routes: RouterRoute[]) {
  return routes.map((route) => (
    <Route key={route.path} path={route.path} element={<route.element />}>
      {route.children && createRoutes(route.children)}
    </Route>
  ));
}
