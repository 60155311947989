import { GroupList } from "components/Article/Groups";
import { useEffect, useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import { Navigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  fetchArticleGroupById,
  fetchArticlesByGroupId,
} from "services/articleGroups";
import { ArticleGroup } from "types/articleGroup";

export function Group() {
  const [groupById, setGroupById] = useState<ArticleGroup>();
  let { id } = useParams();

  const getGroupById = async (id: number) => {
    try {
      const group: ArticleGroup = await fetchArticleGroupById(id);
      if (group.children.length === 0) {
        const articles = await fetchArticlesByGroupId(id);
        group.articles = articles;
      }

      setGroupById(group);
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : (err as string);
      toast.error(errorMessage);
    }
  };
  useEffect(() => {
    const parsedId = parseInt(id || "");

    if (isNaN(parsedId)) {
      <Navigate to="/" />;
      return;
    }
    getGroupById(parsedId);
  }, [id]);

  return (
    <main>
      <Container>
        {groupById !== undefined ? (
          <GroupList group={groupById} />
        ) : (
          <Spinner animation="border" />
        )}
      </Container>
    </main>
  );
}
