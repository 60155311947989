import { axios, APIResponse } from "lib/axios";
import { ArticleCategoryInterface } from "types/articleCategory";

export async function fetchArticleCategories(): Promise<
  ArticleCategoryInterface[]
> {
  return (
    await axios.get<APIResponse<ArticleCategoryInterface[]>>(
      "/articles/categories"
    )
  ).data.data;
}
