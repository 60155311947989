import Card from "react-bootstrap/Card";
import { ArticleCategoryInterface } from "types/articleCategory";
import { Link } from "react-router-dom";

export type ProductIdOnClick = (id: number) => void;

// TODO rename???. ProductCard = CategoryCard
export interface ProductCardProps {
  productCard: ArticleCategoryInterface;
}

// TODO rename???. ProductCard = CategoryCard
export function ProductCard({ productCard }: ProductCardProps) {
  return (
    <Link to={`/category/${productCard.id}`}>
      <Card className={`product-card text-center mx-5 transform-hover`}>
        <Card.Title className="mb-0">
          <h2 className="mt-1">{productCard.names[0].name}</h2>
          <hr className="mb-1" />
        </Card.Title>
        <Card.Img
          variant="top"
          src={productCard.image.src}
          style={{ aspectRatio: "1 / 1" }}
        />
      </Card>
    </Link>
  );
}

export default ProductCard;
