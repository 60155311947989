import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { ContactPerson, UserCountry } from "types/contactperson";
import {
  fetchContactPersonByPostCode,
  fetchContactPersonCountry,
} from "services/contactperson";
import { toast } from "react-toastify";
import ContactPersonListItem from "components/card/ContactPerson/ContactPersonListItem";
import { useTranslation } from "react-i18next";

export function ContactPersonSearch() {
  const { t } = useTranslation();
  const [form, setForm] = useState({
    country: "25",
    postCode: "",
  });
  const [country, setCountry] = useState<UserCountry[]>([]);
  const [contactPerson, setContactPerson] = useState<ContactPerson | null>(
    null
  );

  window.addEventListener("keydown", (e) => {
    if (e.key === "(" && e.keyCode === 56) {
      if (document.getElementById("country") !== document.activeElement) {
        e.preventDefault();
        console.log("country focused");
        document.getElementById("country")?.focus();
      } else {
        return true;
      }
    }
  });

  const searchContactPerson = async (country: string, postCode: string) => {
    try {
      const contactperson: ContactPerson = await fetchContactPersonByPostCode(
        country,
        postCode
      );
      setContactPerson(contactperson);
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : (err as string);
      toast.error(errorMessage);
      setContactPerson(null);
    }
  };

  const fetchCountry = async () => {
    try {
      const contactcountry: UserCountry[] = await fetchContactPersonCountry();
      setCountry(contactcountry);
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : (err as string);
      toast.error(errorMessage);
    }
  };

  const handleSelectUpdate = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const nextFormState = {
      ...form,
      [event.target.name]: event.target.value,
      postCode: "",
    };
    setForm(nextFormState);
    if (nextFormState.country !== "25") {
      searchContactPerson(nextFormState.country, "00");
    } else {
      setContactPerson(null);
    }
  };

  const handleFieldUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nextFormState = {
      ...form,
      [event.target.name]: event.target.value,
    };
    setForm(nextFormState);

    if (nextFormState.postCode.length >= 2) {
      searchContactPerson(nextFormState.country, nextFormState.postCode);
    }
  };

  useEffect(() => {
    fetchCountry();
  }, []);

  return (
    <>
      <Row className="align-items-center justify-items-center">
        <Col md="6" data-aos="fade-in">
          <h2>
            {t("your")} <span className="company-name text-danger">müba</span>{" "}
            {t("contactperson")}
          </h2>
          <span>{t("contactperson.hint1")}</span>
          <p>{t("contactperson.hint2")}</p>
          <Form.Select
            id="country"
            className="input-field-contact"
            placeholder="Land"
            name="country"
            value={form.country}
            onChange={handleSelectUpdate}
          >
            {country.map((c) => (
              <option value={c.id} key={c.id}>
                {c.names[0]?.name || "N/A"}
              </option>
            ))}
          </Form.Select>
          <div className="hint-box" tabIndex={0}>
            Mit "(" direkt in das Dropdown springen
          </div>
          <br />
          {form.country === "25" ? (
            <Form.Control
              className="input-field-contact"
              type="number"
              placeholder={t("contactperson.plz")}
              name="postCode"
              value={form.postCode}
              onChange={handleFieldUpdate}
            />
          ) : (
            <></>
          )}
        </Col>
      </Row>
      <Row className="align-items-center justify-items-center">
        <Col md={6} data-aos="fade-in">
          {contactPerson != null && (
            <ContactPersonListItem
              contactPerson={contactPerson.salesRepresentative}
              key={contactPerson.salesRepresentative.id}
              imageHeight={450}
            />
          )}
        </Col>
        <Col md={6}>
          {contactPerson != null &&
            contactPerson.dispatcher != null &&
            contactPerson.dispatcher.id !==
              contactPerson.salesRepresentative.id && (
              <ContactPersonListItem
                contactPerson={contactPerson.dispatcher}
                key={contactPerson.dispatcher.id}
                imageHeight={450}
              />
            )}
        </Col>
      </Row>
    </>
  );
}
