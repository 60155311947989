import Container from "react-bootstrap/Container";
import { DataTable } from "components/table/table";
//import {Jobs} from "types/career/jobs";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { JobAdvertisement } from "../../types/jobAdvertisement";
import { toast } from "react-toastify";
import { fetchJobAdvertisement } from "services/job-advertisement";

export function Career() {
  const [jobAdvertisements, setJobAdvertisements] = useState<
    JobAdvertisement[]
  >([]);
  const { t } = useTranslation();

  const getJobAdvertisement = async () => {
    try {
      const jobAdvertisement: JobAdvertisement[] =
        await fetchJobAdvertisement();
      setJobAdvertisements(jobAdvertisement);
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : (err as string);
      toast.error(errorMessage);
    }
  };

  useEffect(() => {
    getJobAdvertisement();
  }, []);

  return (
    <main>
      <section className="section-secondary">
        <h1 className="text-center">{t("career.title")}</h1>

        <Container data-aos="fade-up">
          <h2 className="text-center">{t("career.openjobs")}</h2>
          <hr />
        </Container>
        <DataTable jobAdvertisement={jobAdvertisements} />
      </section>
    </main>
  );
}

export default Career;
