import ContactPersonListItem from "./ContactPersonListItem";
import { ContactPerson } from "types/contactperson";
import { User } from "types/users";

export interface ContactPersonListProps {
  contactPersons: User;
  height?: number;
  textHeight?: number;
  imageHeight?: number;
}

export function ContactPersonList({
  contactPersons,
  height,
  textHeight,
  imageHeight = 500,
}: ContactPersonListProps) {
  return (
    <>
      <ContactPersonListItem
        contactPerson={contactPersons}
        height={height}
        imageHeight={imageHeight}
        textHeight={textHeight}
      />
    </>
  );
}
