// Components
import { AppFooter } from "components/app";
import { AppNavbar } from "components/app/Navbar";
import { ScrollToTopButton } from "components/app/ScrollToTopButton";
import { AppTopbar } from "components/app/Topbar";
import AppRoutes from "components/routing/AppRoutes";
import { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

// Libs
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";

function App() {
  const { t } = useTranslation();
  const [popupCookies, setPopupCookies] = useState(
    localStorage.getItem("cookies") === "true"
  );

  useEffect(() => {
    localStorage.setItem("cookies", String(popupCookies));
  }, [popupCookies]);
  return (
    <div className="app">
      {popupCookies ? (
        <></>
      ) : (
        <>
          <div className="dont-show"></div>
          <Card className="popup p-2">
            <Card.Title className="text-center">Cookies</Card.Title>
            <Card.Text>{t("cookies.text")}</Card.Text>
            <Button variant="danger" onClick={() => setPopupCookies(true)}>
              {t("cookies.button")}
            </Button>
          </Card>
        </>
      )}
      <div className="content-wrapper">
        <AppTopbar />
        <AppNavbar />
        <ToastContainer position="top-right" />
        <AppRoutes />
        <ScrollToTopButton />
      </div>
      <AppFooter />
    </div>
  );
}

export default App;
