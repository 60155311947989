// Hooks
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { NavDropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

// Services
import { fetchArticleCategories } from "services/articleCategories";
import { fetchArticleCategoryById } from "services/articleGroups";
import {
  fetchArticleGroupById,
  fetchArticlesByGroupId,
} from "services/articleGroups";

// Types
import {
  ArticleCategoryInterface,
  ArticleCategoryDetails,
} from "types/articleCategory";
import { ArticleGroup } from "types/articleGroup";

// COmponents
import NavLink from "components/routing/NavLink";

export interface NavDropDownSubmenusProps {
  onClick?: () => void;
}

export function NavDropDownSubmenus({ onClick }: NavDropDownSubmenusProps) {
  const { t } = useTranslation();
  const location = useLocation();

  const [categories, setCategories] = useState<ArticleCategoryInterface[]>([]);
  const [selectedCategory, setSelectedCategory] =
    useState<ArticleCategoryDetails | null>(null);

  const [selectedGroup, setSelectedGroup] = useState<ArticleGroup | null>(null);
  const [mainDropdownExpanded, setMainDropdownExpanded] = useState(false);

  const navigate = useNavigate();

  const getCategories = async () => {
    try {
      const categories = await fetchArticleCategories();
      setCategories(categories);
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : (err as string);
      toast.error(errorMessage);
    }
  };

  const handleCategorySelected = async (id: number) => {
    try {
      const category = await fetchArticleCategoryById(id);
      setSelectedCategory(category);
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : (err as string);
      toast.error(errorMessage);

      return Promise.reject(errorMessage);
    }
  };

  const handleGroupSelected = async (id: number) => {
    try {
      const group = await fetchArticleGroupById(id);
      if (group.children.length === 0) {
        const articles = await fetchArticlesByGroupId(id);
        group.articles = articles;
      }
      setSelectedGroup(group);
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : (err as string);
      toast.error(errorMessage);

      return Promise.reject(errorMessage);
    }
  };

  //navigate to Article, not need @time
  /*const handleArticleSelected = (id: number) => {
    navigate("/products/" + id);
    reset();
  };*/

  const handleGroupNavigation = (id: number) => {
    navigate("/group/" + id);
    reset();
  };

  const reset = () => {
    setMainDropdownExpanded(false);
    setSelectedGroup(null);
    setSelectedCategory(null);

    if (onClick !== undefined) {
      onClick();
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    setMainDropdownExpanded(false);
  }, [location.pathname]);

  return (
    <NavDropdown
      title={t("nav.products")}
      className="my-1"
      autoClose={true}
      onClick={() => {
        if (mainDropdownExpanded === true) {
          reset();
        } else {
          setMainDropdownExpanded(true);
        }
      }}
    >
      <NavDropdown.Item as="span" onClick={onClick} eventKey="articleDropdown">
        <NavLink to={{ name: "Products" }}>
          <h6 className="my-1">{t("home.deliveryProgramm")}</h6>
        </NavLink>
      </NavDropdown.Item>
      {categories.map((category) => (
        <NavDropdown.Item
          as="span"
          eventKey="categories"
          key={"category-" + category.id}
        >
          <NavDropdown
            title={category.names[0]?.name || "N/A"}
            className="my-1"
            drop={window.innerWidth >= 1070 ? "end" : "down"}
            onClick={(event) => {
              event.stopPropagation();
              handleCategorySelected(category.id);
            }}
            autoClose={true}
            show={
              selectedCategory != null && selectedCategory.id === category.id
            }
          >
            <b
              style={{ cursor: "pointer" }}
              className="d-flex align-items-end justify-content-end mx-2"
              onClick={(event) => {
                event.stopPropagation();
                reset();
              }}
            >
              X
            </b>
            {selectedCategory != null && (
              <NavDropdown.Item as="span">
                {selectedCategory.groups.map((group) => (
                  <NavDropdown
                    key={
                      "category-" + selectedCategory.id + "-group-" + group.id
                    }
                    title={group.names[0]?.name || "N/A"}
                    drop={window.innerWidth >= 1070 ? "end" : "down"}
                    onClick={(event) => {
                      event.stopPropagation();
                      //this function go to articlegroup page | handleGroupSelected goes to ArticleList
                      handleGroupNavigation(group.id);
                    }}
                    autoClose={true}
                    show={
                      selectedGroup != null &&
                      (selectedGroup.id === group.id ||
                        selectedGroup.parentGroupId === group.id)
                    }
                  >
                    <ul className="ps-0" style={{ cursor: "pointer" }}>
                      <b
                        style={{ cursor: "pointer" }}
                        className="d-flex align-items-end justify-content-end mx-2"
                        onClick={(event) => {
                          event.stopPropagation();
                          setSelectedGroup(null);
                        }}
                      >
                        X
                      </b>
                      {selectedGroup != null &&
                        selectedGroup.children.length > 0 &&
                        selectedGroup.children.map((child) => (
                          <NavDropdown.Item
                            as="span"
                            key={
                              "category-" +
                              selectedCategory.id +
                              "-group-" +
                              group.id +
                              "-child-" +
                              child.id
                            }
                            onClick={(event) => {
                              event.stopPropagation();
                              handleGroupSelected(child.id);
                            }}
                          >
                            <div
                              onClick={(event) => {
                                event.stopPropagation();
                                handleGroupNavigation(child.id);

                                reset();
                              }}
                            >
                              <b>{child.names[0]?.name || "N/A"}</b>
                            </div>
                          </NavDropdown.Item>
                        ))}
                      {/* Logic to Show Article in Groups, not wanted @time
                      selectedGroup != null &&
                        selectedGroup.children.length === 0 &&
                        selectedGroup.articles.map((article) => (
                          <NavDropdown.Item
                            as="span"
                            key={
                              "category-" +
                              selectedCategory.id +
                              "-group-" +
                              group.id +
                              "-article-" +
                              article.id
                            }
                            onClick={(event) => {
                              event.stopPropagation();
                              handleArticleSelected(article.id);
                            }}
                          >
                            <li>
                              <b>{article.number}</b>{" "}
                              <b>{article.descriptions[0]?.title || "N/A"}</b>
                            </li>
                          </NavDropdown.Item>
                          ))*/}
                    </ul>
                  </NavDropdown>
                ))}
              </NavDropdown.Item>
            )}
          </NavDropdown>
        </NavDropdown.Item>
      ))}
    </NavDropdown>
  );
}
