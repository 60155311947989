import { ContactPersonList } from "components/card/ContactPerson";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { fetchCompanyById } from "services/team";
import { User } from "types/users";

export function Team() {
  const { t } = useTranslation();

  const [teamEmployees, setTeamEmployees] = useState<User[]>([]);

  const fetchEmployees = async () => {
    try {
      const company = await fetchCompanyById(1);
      setTeamEmployees(
        company.employees.map((e) => ({
          ...e,
          data: {
            ...e.data,
            mobileNr: null,
          },
          email: null,
        }))
      );
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : (err as string);
      toast.error(errorMessage);
    }
  };

  useEffect(() => {
    fetchEmployees();
  }, []);

  return (
    <main>
      <section>
        <Container>
          <Row>
            {teamEmployees.map((t) => (
              <Col xl={3} md={4} sm={6} xs={12} key={t.id}>
                <ContactPersonList
                  contactPersons={t}
                  imageHeight={340}
                  height={470}
                  textHeight={40}
                />
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </main>
  );
}
