import { axios } from "lib/axios";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Image from "react-bootstrap/Image";
import GER_FLAG from "assets/logos/GER_Flag.png";
import GB_FLAG from "assets/logos/GB_Flag.png";

const FALLBACK_LOCALE = process.env.REACT_APP_I18N_FALLBACK_LOCALE || "us";

const AVAILABLE_LANGUAGES = [
  {
    translationKey: "German",
    langCode: "de",
    countryFlagCode: "DE",
    flagImage: GER_FLAG,
  },
  {
    translationKey: "Englisch",
    langCode: "us",
    countryFlagCode: "GB",
    flagImage: GB_FLAG,
  },
];

export function LanguageSwitcher() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (langCode: string) => {
    i18n.changeLanguage(langCode, (err) => {
      if (err) return console.error("Language can not be found", err);
      axios.defaults.headers.common["Accept-Language"] = langCode;
      localStorage.setItem("language", langCode);
      window.location.reload();
    });
  };

  const currentLanguage =
    AVAILABLE_LANGUAGES.find((lang) => lang.langCode === i18n.language) ||
    AVAILABLE_LANGUAGES.find((lang) => lang.langCode === FALLBACK_LOCALE)!;

  return (
    <Dropdown>
      <Dropdown.Toggle variant="white">
        <Image
          src={currentLanguage.flagImage}
          height={18}
          style={{ objectFit: "contain" }}
        />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {AVAILABLE_LANGUAGES.map((lang) => (
          <Dropdown.Item
            key={lang.langCode}
            onClick={() => changeLanguage(lang.langCode)}
          >
            <Image
              src={lang.flagImage}
              className="mx-1"
              height={16}
              style={{ objectFit: "contain", width: "16px" }}
            />
            {t(lang.translationKey)}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
