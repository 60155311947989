import { RouterRoute } from "types/routing";
import { Home } from "pages/Home";
import { About } from "pages/About";
import { Career } from "pages/Career/Career";
import { ContactPersonPage } from "pages/ContactPerson/ContactPerson";
import { Products } from "pages/Products/Products";
import { PrivacyPolicy } from "pages/PrivacyPolice/PrivacyPolicy";
import { Impressum } from "pages/PrivacyPolice/Impressum";
import { Contact } from "pages/Contact";
import { Attachment } from "pages/Downloads";
import { DownloadsBrochures} from "pages/DownloadsBrochures";
import { DownloadsCertificates} from "pages/DownloadsCertificates";
import { DownloadsManuals } from "pages/DownloadsManuals";
import { DownloadsNewItems } from "pages/DownloadsNewItems";
import { ArticleCategory } from "pages/ArticleCategory";
import { Group } from "pages/Group";
import { AGB } from "pages/PrivacyPolice";
import { Management } from "pages/Management/Management";
import { ProductDetails } from "../pages/ProductDetails/ProductDetails";
import { Team } from "pages/Team/Team";

export const routes: RouterRoute[] = [
  {
    path: "/",
    name: "Home",
    element: Home,
  },
  {
    path: "*",
    name: "Home",
    element: Home,
  },
  {
    path: "/about",
    name: "About",
    element: About,
  },
  {
    path: "/career",
    name: "Career",
    element: Career,
  },
  {
    path: "/contactpersons",
    name: "ContactPerson",
    element: ContactPersonPage,
  },
  {
    path: "/management",
    name: "Management",
    element: Management
  },
  {
    path: "/team",
    name: "Team",
    element: Team
  },
  {
    path: "/products/:id",
    name: "ProductDetails",
    element: ProductDetails
  },
  {
    path: "/products",
    name: "Products",
    element: Products,
  },
  {
    path: "/privacypolicy",
    name: "PrivacyPolice",
    element: PrivacyPolicy,
  },
  {
    path: "/impressum",
    name: "Impressum",
    element: Impressum,
  },
  {
    path: "/agb",
    name: "AGB",
    element: AGB,
  },
  {
    path: "/contact",
    name: "Contact",
    element: Contact,
  },
  {
    path: "/DownloadsBrochures",
    name: "DownloadsBrochures",
    element: DownloadsBrochures,
  },
  {
    path: "/DownloadsCertificates",
    name: "DownloadsCertificates",
    element: DownloadsCertificates,
  },
  {
    path: "/DownloadsNewItems",
    name: "DownloadsNewItems",
    element: DownloadsNewItems,
  },
  {
    path: "/DownloadsManuals",
    name: "DownloadsManuals",
    element: DownloadsManuals,
  },

  {
    path: "/category",
    name: "Category",
    element: ArticleCategory,
  },
  {
    path: "/category/:id",
    name: "Category",
    element: ArticleCategory,
  },
  {
    path: "/group",
    name: "Group",
    element: Group,
  },
  {
    path: "/group/:id",
    name: "Group",
    element: Group,
  },

];

export function getRouteByName(name: string): RouterRoute | undefined {
  return routes.find((route) => route.name === name);
}
