import { ContactPerson, UserCountry } from "types/contactperson";
import { axios, APIResponse } from "lib/axios";

/**
 * Fetches contact persons from API
 *
 * @returns {Promise<ContactPeron[]>} Promise<ContactPerson[]>
 */
export async function fetchContactPersons(): Promise<ContactPerson[]> {
  return (await axios.get<APIResponse<ContactPerson[]>>("/responsible")).data
    .data;
}

/**
 * Fetches contactPerson country from API
 *
 * @returns {Promise<ContactPersonCountry[]>} Promise<ContactPersonCountry[]>
 */
export async function fetchContactPersonCountry(): Promise<UserCountry[]> {
  return (await axios.get<APIResponse<UserCountry[]>>("/users/countries")).data
    .data;
}

/**
 * Search contact person by postCode
 *
 * @returns {Promise<ContactPeron | null>} Promise<ContactPerson | null>
 */
export async function fetchContactPersonByPostCode(
  country: string,
  postCode: string
): Promise<ContactPerson> {
  return (
    await axios.get<APIResponse<ContactPerson>>(
      "/responsible/" + country + "/" + postCode
    )
  ).data.data;
}
