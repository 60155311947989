import Card from "react-bootstrap/Card";
import altImage from "assets/ansprechpartner/altImage.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { convertPhonenumber } from "services/convertPhonenumber";
import { User } from "types/users";

export interface ContactPersonListItemProps {
  contactPerson: User;
  height?: number | string;
  textHeight?: number | string;
  imageHeight?: number;
}

export function ContactPersonListItem({
  contactPerson,
  height,
  textHeight,
  imageHeight = 500,
}: ContactPersonListItemProps) {
  return (
    <Card data-aos="fade-up" style={{ height: height }}>
      <Card.Img
        variant="top"
        height={imageHeight}
        style={{
          aspectRatio: "1 / 1",
          objectFit: "cover",
          objectPosition: "0 5%",
          width: "100%",
        }}
        src={
          contactPerson.image?.src !== "" ? contactPerson.image?.src : altImage
        }
      />
      <Card.Body>
        <Card.Title>
          <h2 className="contact-person-names">{contactPerson.firstname}</h2>
          <h2 className="contact-person-names">{contactPerson.lastname}</h2>
        </Card.Title>
        <Card.Text style={{ height: textHeight }}>
          {contactPerson.data.jobTitle ? (
            <b>{contactPerson.data.jobTitle}</b>
          ) : (
            <br />
          )}
          <br />
          {contactPerson.email !== null ? (
            <b>
              <FontAwesomeIcon icon={faEnvelope} />{" "}
              <a
                href={`mailto:${contactPerson.email}`}
                className="mx-2 text-black"
              >
                {contactPerson.email}{" "}
              </a>
            </b>
          ) : (
            <></>
          )}
          {contactPerson.data.mobileNr !== null ? (
            <b>
              <br />
              <FontAwesomeIcon icon={faPhone} />
              {contactPerson.data.mobileNr !== null ? (
                <a
                  href={`tel:${convertPhonenumber(
                    contactPerson.data.mobileNr
                  )}`}
                  className="mx-2 text-black"
                >
                  {contactPerson.data.mobileNr}
                </a>
              ) : (
                <span className="mx-3"> -</span>
              )}
            </b>
          ) : (
            <></>
          )}
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default ContactPersonListItem;
