import { AttachmentGroups } from "types/downloads/index";
import { AttachmentListItem } from "./AttachmentListItem";

export interface AttachmentGroupListProps {
  attachmentGroups: AttachmentGroups[];
}

export function AttachmentGroupList({
  attachmentGroups,
}: AttachmentGroupListProps) {
  return (
    <>
      {attachmentGroups.map((ag) => (
        <AttachmentListItem attachments={ag.attachments} key={ag.id} />
      ))}
    </>
  );
}
