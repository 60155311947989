import { ReactNode } from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import { getRouteByName } from "router/index";
import { LinkTo } from "types/routing";

export interface LinkProps {
  className?: string;
  to: LinkTo;
  children: ReactNode;
}

function Link({ className, to, children }: LinkProps) {
  let href = "/";
  if (typeof to === "string") {
    href = to;
  } else {
    const route = getRouteByName(to.name);
    if (route) {
      href = route.path;
    }
  }

  return (
    <ReactRouterLink to={href} className={className}>
      {children}
    </ReactRouterLink>
  );
}

export default Link;
