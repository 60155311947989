import { useEffect, useState, useRef } from "react";
import { useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
import {
    fetchNewPdfDownloads,
    fetchPdfById,
    fetchPdfDownloads,
} from "services/downloads";
import { AttachmentGroups } from "types/downloads";
import { AttachmentGroupList } from "components/Downloads/AttachmentGroupList";
import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export function DownloadsCertificates() {
    const { t } = useTranslation();

    const [certificate, setCertificate] = useState<AttachmentGroups[]>([]);


    const fetchCertificates = async () => {
        try {
            const certificate: AttachmentGroups[] = await fetchPdfById(3);
            setCertificate(certificate);
        } catch (err) {
            const errorMessage = err instanceof Error ? err.message : (err as string);
            toast.error(errorMessage);
        }
    };

    useEffect(() => {
        fetchCertificates();

    }, []);
    return (

        <main>
            <section className="section-secondary">

                <Container >
                    {/** condition if certificate <= 0  */}
                    {certificate.length <= 0 ? (
                        <>
                        
                            <h1 id="certificates" className="text-center d-none d-xl-block">{t("attachments.certificate")}</h1>
                            <h2 id="certificates" className="text-center d-xl-none">{t("attachments.certificate")}</h2>
                            <hr />

                        </>
                    ) : (

                        <>
                            <h1 id="certificates" className="text-center d-none d-xl-block">{t("attachments.certificate")}</h1>
                            <h2 id="certificates" className="text-center d-xl-none">{t("attachments.certificate")}</h2>
                            <hr />
                            <b>{certificate[0]?.names[0]?.title}</b>
                            <AttachmentGroupList attachmentGroups={certificate} />
                        </>

                    )}



                </Container>
            </section>
        </main>
    );

}