import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  fetchArticleBreadcrumbs,
  fetchArticleGroupBreadcrumbs,
  fetchArticleCategoryBreadcrumbs,
} from "services/productBreadCrumb";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { ProductBreadcrumbItem } from "types/productBreadCrumb";
import { Link } from "react-router-dom";

export default function ProductBreadcrumb() {
  const [breadCrumbs, setBreadCrumbs] = useState<ProductBreadcrumbItem[]>([]);
  const location = useLocation();

  // Refetch/Rebuild bread crumbs
  const updateBreadCrumbs = async () => {
    const locationParts = location.pathname
      .split("/")
      .filter((part) => part !== "");
    if (locationParts.length < 2) {
      return;
    }

    let items: ProductBreadcrumbItem[] = [];
    switch (locationParts[0].toLowerCase()) {
      case "category":
        const categoryId = parseInt(locationParts[1]);
        items = await fetchArticleCategoryBreadcrumbs(categoryId);
        break;
      case "group":
        const groupId = parseInt(locationParts[1]);
        items = await fetchArticleGroupBreadcrumbs(groupId);
        break;
      case "products":
        const productId = parseInt(locationParts[1]);
        items = await fetchArticleBreadcrumbs(productId);
        break;
    }
    setBreadCrumbs(items);
  };

  // call updateBreadCrumbs if location.pathname changes
  useEffect(() => {
    updateBreadCrumbs();
  }, [location.pathname]);

  return (
    <Breadcrumb className="pt-4">
      {breadCrumbs.map((item, index) => (
        <Breadcrumb.Item
          linkAs={Link}
          linkProps={{ to: item.href }}
          key={index}
        >
          <span style={{ color: "#990000" }}>{item.text}</span>
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
}
