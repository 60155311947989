import { AttachmentGroups } from "types/downloads/index";
import { axios, APIResponse } from "lib/axios";

/**
 * Fetches PDF from API
 *
 * @returns {Promise<DownloadPdf[]>} Promise<DownloadPdf[]>
 */
export async function fetchPdfDownloads(): Promise<AttachmentGroups[]> {
  return (
    await axios.get<APIResponse<AttachmentGroups[]>>("/attachment/groups/1")
  ).data.data;
}

export async function fetchNewPdfDownloads(): Promise<AttachmentGroups[]> {
  return (
    await axios.get<APIResponse<AttachmentGroups[]>>("/attachment/groups/5")
  ).data.data;
}

export async function fetchAgb(): Promise<AttachmentGroups[]> {
  return (
    await axios.get<APIResponse<AttachmentGroups[]>>("/attachment/groups/6")
  ).data.data;
}

export async function fetchPdfById(id: number): Promise<AttachmentGroups[]> {
  return (
    await axios.get<APIResponse<AttachmentGroups[]>>("/attachment/groups/" + id)
  ).data.data;
}
