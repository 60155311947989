import { APIResponse, axios } from "../../lib/axios";
import { JobAdvertisement } from "../../types/jobAdvertisement";
/*
get => job-advertismenet/
get => job-advertismenet/types/id
post => job-advertismenet/types/type/create
post => job-advertismenet/update/(0-9)
get => job-advertismenet/delete/(0-9)
 */


export async function fetchJobAdvertisement(): Promise<JobAdvertisement[]> {
    return (await axios.get<APIResponse<JobAdvertisement[]>>("/job-offer")).data.data;
}