import { CompanyInformationCard } from "types/companyInformation";
import { CompanyInformationCardListItem } from "./CompanyInformationCardListItem";

import { Col, Row } from "react-bootstrap";
interface CompanyInformationCardListProps {
  companyInformations: CompanyInformationCard[];
}

export function CompanyInformationCardList({
  companyInformations,
}: CompanyInformationCardListProps) {
  return (
    <Row>
      {companyInformations.map((companyInformation) => (
        <Col md="6" xs="6" key={companyInformation.detail}>
          <CompanyInformationCardListItem
            companyInformation={companyInformation}
          />
        </Col>
      ))}
    </Row>
  );
}
