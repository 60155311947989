import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

import facebook from "assets/icons/square-facebook.svg";
import instagram from "assets/icons/square-instagram.svg";
import linkedin from "assets/icons/linkedin.svg";
import youtube from "assets/icons/square-youtube.svg";

export function ContactIcons() {
  return (
    <Container className="d-flex">
      <Row>
        <Col md="2" sm="2" xs="2">
          <a
            href="https://www.facebook.com/people/m%C3%BCba-Bauger%C3%A4te-Bauger%C3%BCste/61557663857907/"
            target="_blank"
            rel="noreferrer"
          >
            <Image className="contact-icon" src={facebook} height={50} />
          </a>
        </Col>
        <Col md="2" sm="2" xs="2">
          <a
            href="https://www.instagram.com/mueba_deutschland/"
            target="_blank"
            rel="noreferrer"
          >
            <Image className="contact-icon" src={instagram} height={50} />
          </a>
        </Col>
        <Col md="2" sm="2" xs="2">
          <a
            href="https://de.linkedin.com/company/m%C3%BCba-m%C3%BCller-baum-gmbh-co-kg"
            target="_blank"
            rel="noreferrer"
          >
            <Image className="contact-icon" src={linkedin} height={50} />
          </a>
        </Col>
        <Col md="2" sm="2" xs="2">
          <a
            href="https://www.youtube.com/watch?v=eCjxyxq9ZA0"
            target="_blank"
            rel="noreferrer"
          >
            <Image className="contact-icon" src={youtube} height={50} />
          </a>
        </Col>
      </Row>
    </Container>
  );
}
