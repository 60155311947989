import Table from "react-bootstrap/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { useState } from "react";
import { ContactPersonList } from "components/card/ContactPerson/ContactPersonList";
import { CareerContact } from "components/career/careerContact/CareerContact";

import ihk22 from "assets/logos/ihk24.png";

import { ContactPerson } from "types/contactperson";
import { useTranslation } from "react-i18next";
import claudiahartnig from "../../assets/ansprechpartner/Hartnig-Claudia.jpg";
import lukasmueller from "../../assets/ansprechpartner/Mueller-Lukas.jpg";
import { JobAdvertisement } from "../../types/jobAdvertisement";
import { convertCharacter } from "../../services/convertCharacter";

interface JobAdvertisementProps {
  jobAdvertisement: JobAdvertisement[];
}

const contactPersons: ContactPerson[] = [
  {
    id: -1,
    zipcode: null,
    country: {
      id: -1,
      names: [],
    },
    salesRepresentative: {
      id: -1,
      name: "",
      username: "",
      firstname: "Claudia",
      lastname: "Hartnig",
      email: "c.hartnig@mueba.de",
      image: {
        src: claudiahartnig,
        alt: "",
        id: -1,
      },
      address: {
        street: "",
      },
      phone: "",
      data: {
        jobTitle: "",
        mobileNr: "+49 (2935) 801-20",
      },
      website: "",
      company: {
        name: "",
      },
    },
    dispatcher: null,
  },
  {
    id: -2,
    zipcode: null,
    country: {
      id: -1,
      names: [],
    },
    salesRepresentative: {
      id: -1,
      name: "",
      username: "",
      firstname: "Lukas",
      lastname: "Müller",
      email: "lukas.mueller@mueba.de",
      image: {
        src: lukasmueller,
        alt: "",
        id: -1,
      },
      address: {
        street: "",
      },
      phone: "",
      data: {
        jobTitle: "",
        mobileNr: "+49 (2935) 801-39",
      },
      website: "",
      company: {
        name: "",
      },
    },
    dispatcher: null,
  },
];

export function DataTable({ jobAdvertisement }: JobAdvertisementProps) {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(-1);
  const [toggleApplication, setToggleApplication] = useState(false);

  const handleApplicationClick = () => {
    setToggleApplication((current) => !current);
  };

  const toggle = (index: number) => {
    if (selected === index) {
      return setSelected(-1);
    }
    setSelected(index);
  };

  return (
    <main>
      <Container>
        {jobAdvertisement.length <= 0 ? (
          <>
            <h2 className="text-center" data-aos="fade-up">
              {t("career.nojobs")}
            </h2>
          </>
        ) : (
          <Table striped>
            <thead>
              <tr>
                <th>{t("career.openjobs.table.title")}</th>
                <th>{t("career.openjobs.table.company")}</th>
                <th>{t("career.openjobs.table.location")}</th>
                <th>{t("career.openjobs.table.start")}</th>
              </tr>
            </thead>
            <tbody>
              {jobAdvertisement.map((jat, index) => (
                <tr
                  className="data-table-row"
                  onClick={() => toggle(index)}
                  key={jat.id}
                >
                  <td>{jat.descriptions[0].title} </td>
                  <td>{jat.company.name}</td>
                  <td>
                    <FontAwesomeIcon icon={faLocationDot} />
                    {jat.location.name}
                  </td>
                  <td>{jat.startDate}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        <div className="text-center">
          <h3 className="text-center">
            {t("career.initiativeapplication")}
            <a
              className="none-decoration company-name text-black"
              href="mailto:karriere@mueba.de"
            >
              karriere@mueba.de
            </a>
          </h3>
        </div>
      </Container>
      {selected !== -1 && jobAdvertisement.length ? (
        <Container>
          <Row>
            <Col md="6">
              <div>
                {convertCharacter(
                  jobAdvertisement[selected].descriptions[0].description
                )}
              </div>
              <Button variant="danger" onClick={handleApplicationClick}>
                {t("career.applynow")}
              </Button>
            </Col>
          </Row>
        </Container>
      ) : (
        <>
          <Container>
            <Row>
              <Col md="4">
                <CareerContact />
              </Col>
            </Row>
          </Container>
        </>
      )}
      {toggleApplication ? (
        <section>
          <Container>
            <Row>
              {jobAdvertisement[0].id !== 2 ? (
                <Col md="6" data-aos="fade-up">
                  <Row className="align-items-center">
                    <Col md="8">
                      <span>
                        {t("career.openjobs.recognizedTrainingCompany")} 2020
                      </span>
                    </Col>
                    <Col md="2" sm="2" xs="4">
                      <Image src={ihk22} />
                    </Col>
                  </Row>
                  <span>
                    {t("career.openjobs.contactPerson")}
                    <span className="company-name"> müba </span>
                  </span>
                  <Row>
                    {contactPersons.map((contactPerson) => (
                      <Col md="6" key={contactPerson.id}>
                        <ContactPersonList
                          contactPersons={contactPerson.salesRepresentative}
                        />
                      </Col>
                    ))}
                  </Row>
                </Col>
              ) : (
                <></>
              )}
              <Col md="6">
                <CareerContact />
              </Col>
            </Row>
          </Container>
        </section>
      ) : (
        <></>
      )}
    </main>
  );
}
