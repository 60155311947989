import { axios, APIResponse } from "lib/axios";
import { ProductBreadcrumbItem } from "types/productBreadCrumb";

export async function fetchArticleBreadcrumbs(articleId: number): Promise<ProductBreadcrumbItem[]> {
    return (await axios.get<APIResponse<ProductBreadcrumbItem[]>>(`/articles/${articleId}/breadcrumbs`)).data.data;
}

export async function fetchArticleCategoryBreadcrumbs(categoryId: number): Promise<ProductBreadcrumbItem[]> {
    return (await axios.get<APIResponse<ProductBreadcrumbItem[]>>(`/articles/categories/${categoryId}/breadcrumbs`)).data.data;
}

export async function fetchArticleGroupBreadcrumbs(groupId: number): Promise<ProductBreadcrumbItem[]> {
    return (await axios.get<APIResponse<ProductBreadcrumbItem[]>>(`/articles/groups/${groupId}/breadcrumbs`)).data.data;
}