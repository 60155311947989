import { ContactForm } from "components/contact";
import { Container } from "react-bootstrap";

export function Contact() {
    return (
        <main className="section-secondary">
            <div className="mt-5">
                <Container>
                    <ContactForm />
                </Container>
            </div>
        </main>
    );
}
