import { useEffect, useState, useRef } from "react";
import { useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
import {
    fetchNewPdfDownloads,
    fetchPdfById,
    fetchPdfDownloads,
} from "services/downloads";
import { AttachmentGroups } from "types/downloads";
import { AttachmentGroupList } from "components/Downloads/AttachmentGroupList";
import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";


export function DownloadsNewItems() {
    const { t } = useTranslation();

    const [newPdfDownloads, setNewPdfDownloads] = useState<AttachmentGroups[]>([]);

    const fetchNewAttachments = async () => {
        try {
            const newPdfDownloads: AttachmentGroups[] = await fetchNewPdfDownloads();
            setNewPdfDownloads(newPdfDownloads);
        } catch (err) {
            const errorMessage = err instanceof Error ? err.message : (err as string);
            toast.error(errorMessage);
        }
    };

    useEffect(() => {

        fetchNewAttachments();

    }, []);
    return (
        <main>
             <section className="section-secondary">
            <Container>
                
                    <>
            
                        <h1 id="new-items" className="text-center d-none d-xl-block">{t("attachments.news")}</h1>
                        <h2 id="new-items" className="text-center d-xl-none">{t("attachments.news")}</h2>
                        <hr />
                        <b>{newPdfDownloads[0]?.names[0]?.title}</b>
                        <AttachmentGroupList attachmentGroups={newPdfDownloads} />
                    </>
         
            </Container>
            </section>
        </main>

    );
}