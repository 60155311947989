import { useEffect, useState } from "react";
import { ArticleProduct } from "../../types/articleProduct";
import { Navigate, useParams } from "react-router-dom";
import { fetchArticleProducts } from "../../services/articleProducts";
import { toast } from "react-toastify";
import { ProductDetailsList } from "../../components/ProductDetails";
import { Spinner } from "react-bootstrap";
import Container from "react-bootstrap/Container";

export function ProductDetails() {
  const [productById, setProductById] = useState<ArticleProduct>();
  let { id } = useParams();

  const fetchProducts = async (id: string) => {
    try {
      const product: ArticleProduct = await fetchArticleProducts(id);
      setProductById(product);
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : (err as string);
      toast.error(errorMessage);
    }
  };

  useEffect(() => {
    if (id === undefined) {
      <Navigate to="/" />;
      return;
    }
    fetchProducts(id);
  }, [id]);

  return (
    <main>
      {productById !== undefined ? (
        <ProductDetailsList productDetails={productById} />
      ) : (
        <Container>
          <Spinner className="mt-2" animation="border" />
        </Container>
      )}
    </main>
  );
}
